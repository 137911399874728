import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const Call = props => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
        contactJson {
          email
          contact_button_link,
          linkedIn,
          linkedIn_label
        }
    }
   `);
  return (
    <div className="call">
      <div className="call-box-top">
        {data.contactJson.email && (
          <div className="call-email">
            <strong>Email: </strong>
            <a href={`mailto:${data.contactJson.email}`}>
              {data.contactJson.email}
            </a>
          </div>
        )}
        {data.contactJson.linkedIn && (
          <div className="call-linkedIn">
            <strong>LinkedIn: </strong>
            < text 
              style={{color: '#8036ca', cursor: 'pointer'}} 
              role = 'link' 
              onClick={()=> window.open(data.contactJson.linkedIn, '_blank')}
              onMouseOver={e => e.currentTarget.style.textDecoration = 'underline'}
              onMouseOut={e => e.currentTarget.style.textDecoration = 'none'}
            >
              {data.contactJson.linkedIn_label}
            </text>
          </div>
        )}
      </div>
      {props.showButton && (
        <div className="call-box-bottom">
          <a href={data.contactJson.contact_button_link} className="button">Contact</a>
        </div>
      )}
    </div>
  );
};

export default Call;
