import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Call from '../components/Call';

const Projects = props => {
  const team = props.data.team.edges;
  const { intro } = props.data;
  const introImageClasses = `intro-image ${intro.frontmatter.intro_image_absolute &&
     'intro-image-absolute'} ${intro.frontmatter.intro_image_hide_on_mobile && 'intro-image-hide-mobile'}`;
  return (
    <Layout bodyClass="page-projects">
      <SEO title="Projects and Case studies" />

      <div className="intro">
        <div className="container">
          <div className="row justify-content-start">
            <div className="col-12 col-md-7 col-lg-6 order-2 order-md-1">
              <div dangerouslySetInnerHTML={{ __html: intro.html }} />
            </div>
            {intro.frontmatter.intro_image && (
              <div className="col-12 col-md-5 col-lg-6 order-1 order-md-2 position-relative">
                <img alt={intro.frontmatter.title} className={introImageClasses} src={intro.frontmatter.intro_image} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div style={{
        display:'flex', 
        flexDirection: 'column', 
        alignContent: 'center', 
        justifyContent: 'center', 
        alignItems: 'center',
        gap: '50px'}}>
        {team.filter(edge => (edge.node.frontmatter.promoted)).map(({ node }) => (
          <div key={node.id} className="col-12 col-md-6 mb-2">
            <div className="project project-summary project-summary-large" onClick={()=> window.open(node.frontmatter.projectUrl, '_blank')}>
              
              {node.frontmatter.image && (
                <div className="project-image">
                  <img alt={`photo of ${node.frontmatter.title}`} src={node.frontmatter.image} />
                </div>
              )}
              <div className="project-meta">
                <h2 className="project-name">{node.frontmatter.title}</h2>
                <p className="project-description">{node.frontmatter.jobtitle}</p>
                <div className="project-content">
                  <p>{node.excerpt}</p>
                </div>
                <p className="project-description" style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}>Read More</p>
              </div>

            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ProjectQuery {
    team: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/projects\/.*/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            promoted
            image
            jobtitle
            projectUrl
          }
        }
      }
    }
    intro: markdownRemark(fileAbsolutePath: {regex: "/(projects.md)/"}) {
      html
      frontmatter {
        image
        intro_image
        intro_image_absolute
        intro_image_hide_on_mobile
        title
      }
    }
  }
`;

export default Projects;
